@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap');

.galeriaContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.alumnoTexto{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}