@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Handlee&display=swap');

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.footerGeneral {
  background: rgb(255, 255, 255);
  margin: 0;
}

.footerMargin {
  display: flex;
  margin: 0 24px;
  padding-top: 2rem;
  justify-items: center;
  justify-content: center;
  text-align: justify;
  font-family: 'Arimo', sans-serif;
  font-family: 'Handlee', cursive;
}

aside {
  margin: 0 24px;
}

.derechos {
  margin: 0 125px;
  font-family: 'Arimo', sans-serif;
  font-family: 'Handlee', cursive;
}
