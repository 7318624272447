@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Handlee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Handlee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 20%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
    height: auto;
    background: url(/static/media/Brackground_html.38a7787a.png);
}

body{
    height: 100%;
    margin: 0;
    padding: 0 24px;
}
nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
    border-bottom: 1px solid gray;
  }

  .logoNav {
    padding-top: 5px;
    width: 80px;
    height: 80px;
  }

  .navbarContainer ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 12px;
    font-family: 'Arimo', sans-serif;
    font-family: 'Handlee', cursive;
  }

  .navbarContainer{
    display: flex;
  }

  .navbarContainer ul li a{
    text-decoration: none;
    color: green;
    padding: 8px;
    border-radius: 8px;
  }

  .navbarContainer ul li a:hover{
    border: 2px solid rgb(56, 53, 253);
    color: rgb(56, 53, 253);
  }
.bodyContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}


.homeSaludo{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoSaludo{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.imagenBienvenida{
    display: flex;
    margin: 0;
    margin-top: 30px;
    width: 600px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
}


.footerContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.footerGeneral {
  background: rgb(255, 255, 255);
  margin: 0;
}

.footerMargin {
  display: flex;
  margin: 0 24px;
  padding-top: 2rem;
  justify-items: center;
  justify-content: center;
  text-align: justify;
  font-family: 'Arimo', sans-serif;
  font-family: 'Handlee', cursive;
}

aside {
  margin: 0 24px;
}

.derechos {
  margin: 0 125px;
  font-family: 'Arimo', sans-serif;
  font-family: 'Handlee', cursive;
}

.nosotrosContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.titleNosotros{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoNosotros{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.imagenNosotros{
    display: flex;
    margin: 0;
    margin-top: 30px;
    width: 600px;
    height: 400px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.misionContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.titleMision{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoMision{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.imagenMision{
    display: flex;
    margin: 0;
    margin-top: 30px;
    width: 600px;
    height: 400px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.objetivoContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.titleObjetivo{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoObjetivo{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.imagenObjetivo{
    display: flex;
    margin: 0;
    margin-top: 30px;
    width: 600px;
    height: 400px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.politicasContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.row{
    
}

.titlePoliticas{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoPoliticas{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.containerVarios{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    
}

.imgUno{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroUno{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
}

.imgDos{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroDos{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.2rem;
}

.imgTres{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroTres{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.13rem;
}

.imgCuatro{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroCuatro{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.35rem;
}

.imgCinco{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 115px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroCinco{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.35rem;
}
.requisitosContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.titleRequisitos{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoRequisitos{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.imagenRequisitos{
    display: flex;
    margin: 0;
    margin-top: 30px;
    width: 600px;
    height: 400px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.silderContainer{
    margin: 0 3%;
    overflow-x: hidden;
}

.slider{
    display: flex;
    cursor: grab;
}

.item{
    height: 20rem;
    width: 30rem;
    padding: 2rem;
}

.item img{
    height: auto;
    width: 20rem;
    pointer-events: none;
    

}


.galeriaContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.alumnoTexto{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}
