@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap');

.politicasContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.row{
    
}

.titlePoliticas{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoPoliticas{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.containerVarios{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    
}

.imgUno{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroUno{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
}

.imgDos{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroDos{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.2rem;
}

.imgTres{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroTres{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.13rem;
}

.imgCuatro{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 170px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroCuatro{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.35rem;
}

.imgCinco{
    display: flex;
    margin: 0 24px;
    margin-top: 30px;
    width: 500px;
    height: 115px;
    border-color: rgb(179, 3, 255);
    border-width: 5px;
    border-style: dotted;
}

.textRecuadroCinco{
    display: flex;
    text-align: center;
    margin-top: auto;
    width: 50vh;
    height: 50vh;
    font-size: 1.35rem;
}