@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Luckiest+Guy&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arimo&display=swap');

.nosotrosContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
}

.titleNosotros{
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 50vh;
    margin-right: 50vh;
    font-family: 'Arimo', sans-serif;
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    color: green;
}

.textoNosotros{
    justify-content: center;
    text-align: justify;
    margin: auto;
    font-family: 'Architects Daughter', cursive;
    font-size: 1.5rem;
}

.imagenNosotros{
    display: flex;
    margin: 0;
    margin-top: 30px;
    width: 600px;
    height: 400px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}