.silderContainer{
    margin: 0 3%;
    overflow-x: hidden;
}

.slider{
    display: flex;
    cursor: grab;
}

.item{
    height: 20rem;
    width: 30rem;
    padding: 2rem;
}

.item img{
    height: auto;
    width: 20rem;
    pointer-events: none;
    

}

