@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Handlee&display=swap');

nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 24px;
    border-bottom: 1px solid gray;
  }

  .logoNav {
    padding-top: 5px;
    width: 80px;
    height: 80px;
  }

  .navbarContainer ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 12px;
    font-family: 'Arimo', sans-serif;
    font-family: 'Handlee', cursive;
  }

  .navbarContainer{
    display: flex;
  }

  .navbarContainer ul li a{
    text-decoration: none;
    color: green;
    padding: 8px;
    border-radius: 8px;
  }

  .navbarContainer ul li a:hover{
    border: 2px solid rgb(56, 53, 253);
    color: rgb(56, 53, 253);
  }